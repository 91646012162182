$(function () {
    $("#usedmotorcycles").on("change", "select", function () {
        $("#usedmotorcycles input").prop('disabled', true);
        $.ajax({
            url: "/umbraco/api/UsedMotorcyclesApi/getall",
            data: $("#usedmotorcycles").serialize(),
            dataType: "json",
            cache: true,
            success: function (response) {

                updateDropdown($("#mcmodel"), response.ModelValue, response.Models);
                updateDropdown($("#location"), response.LocationValue, response.Dealers);

                //$("#productlist").html(response)
                $("#usedmotorcycles input").prop('disabled', false);
            },
        });
    });

});


function updateDropdown(el, fieldValue, arr) {
    console.log(el);
    console.log(fieldValue);
    el.find('option').not(':first').remove();
    for (var i = 0; i < arr.length; i++) {
        el.append($("<option></option>").attr("value", encodeURIComponent(arr[i])).text(arr[i]));
    }

    if (arr.indexOf(fieldValue) > -1) {
        el.val(encodeURIComponent(fieldValue));
    } else {
        el.val("");
    }
};

$(function () {
    $("#usedmotorcycles, .usedmcsearch").on("change", "#premiumselection", function () {
        console.log('premium selection');
        $this = this;

        console.log($("#premiumselection").prop('checked'))
        if ($("#premiumselection").prop('checked')) {

            //removes all non BMW brand in dropdown
            $("#brand option[value != 'BMW']").remove();
            $("#engros").attr("disabled", true);
            $("#engros").prop("checked", false);
        } else {
            //Repopulate the Brand list
            $.ajax({
                url: "/umbraco/api/UsedMotorcyclesApi/getall", 
                data: $("#usedmotorcycles").serialize(),
                dataType: "json",
                cache: true,
                success: function (response) {
                    //Removes engro disabled
                    $("#engros").removeAttr("disabled");
                    //Removes extra bmw option
                    $("#brand option[value = 'BMW']").remove();
                    //sets first option back to original
                    $("#brand").prepend("<option value='' selected='selected'>Alle mærker</option>");
                    updateDropdown($("#brand"), response.BrandsValue, response.Brands);

                    //$("#productlist").html(response)
                    $("#usedmotorcycles input").prop('disabled', false);
                },
            });

        }

    });
});


function disableDays() {
    var d = new Date();
    var getTot = daysInMonth(d.getMonth(), d.getFullYear()); //Get total days in a month
    var sat = new Array();   //Declaring array for inserting Saturdays
    var sun = new Array();   //Declaring array for inserting Sundays

    for (var i = 1; i <= getTot; i++) {    //looping through days in month
        var newDate = new Date(d.getFullYear(), d.getMonth(), i)
        if (newDate.getDay() == 0) {   //if Sunday
            sun.push(i);
            $('[data-pika-day="' + i + '"]').attr("disabled", true);
            $('[data-pika-day="' + i + '"]').css('cursor', 'not-allowed');
        }
        if (newDate.getDay() == 6) {   //if Sunday
            sat.push(i);
        }
    }

    //set the disabled day again 
    $(".umbraco-forms-field-wrapper").click(function () {
        for (var j = 0; j < sun.length; j++) {
            $('[data-pika-day="' + sun[j] + '"]').attr("disabled", true);
            $('[data-pika-day="' + sun[j] + '"]').css('cursor', 'not-allowed');
        }

        if ($('.datepickerfieldshadow').val()) {
            var testDate = new Date($('.datepickerfieldshadow').val());
            if (testDate.getDay() == 6) {
                $("option[value='Kl. 13 - 14']").attr("disabled", true);
                $("option[value='Kl. 14 - 15']").attr("disabled", true);
                $("option[value='Kl. 15 - 16']").attr("disabled", true);
                $("option[value='Kl. 16 - 17']").attr("disabled", true);
            } else {
                $("option[value='Kl. 13 - 14']").attr("disabled", false);
                $("option[value='Kl. 14 - 15']").attr("disabled", false);
                $("option[value='Kl. 15 - 16']").attr("disabled", false);
                $("option[value='Kl. 16 - 17']").attr("disabled", false);
            }
        }

    });

    function daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }
}

var disableWeekends = function () {
    $('.umbraco-forms-container  :input[value="Bestille en prøvetur"]').change(function () {
        setTimeout(disableDays, 1000);
    });
};

setTimeout(disableWeekends, 1000);

