$(function () {
    ClickToShowBasket();
    AddToBasketHandler();
    HandleShippingMethods();
    ProductAmountHandler();
    BindVoucher();
    ShowAltertivAdress();
    ShowGiftCardForm();
    AddGiftCardToBasketHandler();
    HandleGiftCardPayment();
    reloadBasket();

});


function HandleGiftCardPayment() {
    $(".giftcardBtn").click(function (e) {
        e.preventDefault();//not to submit form for payment

        var form = $("#addressform");
        form.validate();

        if (form.valid()) {
            $("#giftCardModal").css("display", "block");
            var postData = {
                "postData": "null"
            };
            $.ajax({
                type: 'POST',
                url: "/umbraco/Surface/Voucher/checkForGiftCardPayment",
                data: JSON.stringify(postData),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                success: function (data) {
                    //console.log("data ", data);

                    if (data.success) {

                        var reply = data.message + " <br>" +
                            "Betalt med gavekort " + data.paidWithGiftcard + "<br>" +
                            "Rest til betaling " + data.orderTotal +
                            "<span class='removeGiftCard' data-giftcardcode='" + data.giftCardCode + "'> Fjern gavekort</span>";
                        $("#giftCardReply").removeClass('alert alert-danger');
                        $("#giftCardReply").addClass('alert alert-success');
                        $("#giftCardReply").html(reply);
                        $("#giftCardReply").show();
                    }
                },
                error: function (f1, f2, f3) {
                    //console.log("error");
                }
            });
        }

    });

    $(".submitgiftcard").click(function (e) {
        e.preventDefault();
        var giftCard = $("#giftcard-input").val();

        if (giftCard !== "") {
            var postData = {
                giftCardCode: giftCard
            };

            $.ajax({
                type: 'POST',
                url: "/umbraco/Surface/Voucher/addGiftCardPayment",
                data: JSON.stringify(postData),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                success: function (data) {
                    //console.log("data ", data);
                    var reply = data.message;
                    if (data.success) {
                        reply = data.message + " <br>" +
                            "Betalt med gavekort " + data.paidWithGiftcard + "<br>" +
                            "Rest til betaling " + data.orderTotal +
                            "<span class='removeGiftCard' data-giftcardcode='" + data.giftCardCode + "'>Fjern gavekort</span>";
                        $("#giftCardReply").removeClass('alert alert-danger');
                        $("#giftCardReply").addClass('alert alert-success');
                    } else {
                        $("#giftCardReply").removeClass('alert alert-success');
                        $("#giftCardReply").addClass('alert alert-danger');
                    }

                    $("#giftCardReply").html(reply);

                    $("#giftCardReply").addClass('animated flash');
                    $("#giftCardReply").show();
                    setTimeout(function () {
                        $("#giftCardReply").removeClass('animated flash');
                    }, 500);
                },
                error: function (f1, f2, f3) {
                    //console.log("error");
                }
            });

        }


    });

    $(".modal-content").on("click", ".removeGiftCard", function (e) {
        var $this = $(this);
        var giftCard = $this.data("giftcardcode");
        var postData = {
            "giftCardCode": giftCard
        };

        $.ajax({
            type: 'POST',
            url: "/umbraco/Surface/Voucher/removeGiftCardPayment",
            data: JSON.stringify(postData),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            success: function (data) {
                //console.log("data ", data);
                if (data.success) {
                    $("#giftCardReply").addClass('animated flash');
                    setTimeout(function () {
                        $("#giftCardReply").removeClass('animated flash');
                        $("#giftCardReply").hide();
                    }, 500);

                }
            },
            error: function (f1, f2, f3) {
                //console.log("error");
            }
        });
    })


    $(".submitForPayment").click(function (e) {
        e.preventDefault();
        var form = $("#addressform");
        form.validate();
        form.submit();
    });

};

function closeGiftCardModal() {
    $("#giftCardModal").css("display", "none");
};


//This handle the discount and not the giftcard
function AddGiftCardToBasketHandler() {
    $("#gift-card").on("click", "#AddGiftToBasket", function (e) {
        e.preventDefault();
        var amount = $('#giftamount').val();
        var sku = $('#product-sku').val();
        var recieverName = $('#recieverName').val();
        var giftMessage = $('#giftMessage').val();
        var imgUrl = $("#ImgUrl").val();
        var printedCard = $("#PrintedCard").is(':checked');
        if (amount > 0) {
            $('#amountError').css("display", "none");
            var postData = {
                Amount: amount,
                RecieverName: recieverName,
                GiftMessage: giftMessage,
                Sku: sku,
                IsGiftCard: 'true',
                PrintedCard: printedCard + ''
            };

            $.ajax({
                type: 'POST',
                url: "/umbraco/Api/BasketApi/addToBasket",
                data: JSON.stringify(postData),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                success: function (data) {
                    //console.log(data);
                    populateBasket(data, imgUrl);
                },
                error: function (f1, f2, f3) {
                    //console.log("error");
                }
            });
        }
        else{
            $('#amountError').css("display", "block");
        }
        

    });
}

function populateBasket(data, imgUrl) {
    var target = $('[data-orderlineid=' + data.LineId + ']');
    //console.log("success");
    //console.log("data", data);

    if (target.length <= 0) {
        //Here new item need to be added

        var productLine = '<div class="basket-item orderLineRow" data-orderlineid="' + data.LineId + '">'
            + '<div class="remove"><button type="submit" value="' + data.LineId + '" qty="0" name="RemoveOrderlineId" class="btn line-remove pull-right" title= "Fjern produkt" ><i class="fas fa-times"></i></button></div>'
            + '<div class="image"><a href="#"><img src="' + imgUrl + '" class="img-responsive" alt="' + data.ProductName + '"></a></div>'
            + '<div class="name"><a href="' + data.ProductUrl + '" title="' + data.ProductName + '">' + data.ProductNameShort + ' </a> Pr. stk. ' + data.LinePriceWithDiscount + ' </div >'
            + '<div class="amount">'
            + '<button value="' + data.LineId + '" qty="' + data.LineQty + '" class="btn btn-link minus" name="DecreaseQtyOrderlineId"><i class="fas fa-minus"></i></button>'
            + '<span class="qty product-qty"> ' + data.LineQty + '</span>'
            + '<button value="' + data.LineId + '" qty="' + data.LineQty + '" class="btn btn-link plus" name="IncreaseQtyOrderlineId"><i class="fas fa-plus"></i></button>'
            + '</div>'
            + '<div class="total line-total text-right">' + data.LineTotal + '</div>'
            + '</div >';

        $('#items').append(productLine);


    } else {
        target.find(".qty").addClass('animated flash').text(data.LineQty);
        target.find(".line-total").addClass('animated flash').text(data.LineTotal);
        target.find(".minus, .plus").attr('qty', data.LineQty);
    }
    updateBasketIconCounter(data.numberOfItems);
    ShowBasket();

    $(".sidebasket-subtotal").addClass('animated flash').text(data.SubTotal);
    $(".order-shipping").addClass('animated flash').text(data.ShippingCost);
    $(".order-discounts").addClass('animated flash').text(data.DiscountTotal);
    $(".order-total").addClass('animated flash').text(data.OrderTotal);
    $(".order-moms").addClass('animated flash').text(data.OrderTax);
    $(".shipment-name").text('(' + data.ShippingName + ')');




    showBasketContent();
    setTimeout(function () {
        $(".order-shipping, .order-discounts, .order-total, .order-moms, .shipment-name").removeClass('animated flash');
        target.removeClass('animated flash');
    }, 1000);
}

//Handles button clik for add to basket
function AddToBasketHandler() {

    $("#addToBasket").click(function (e) {

        e.preventDefault();
        var quantity = $('#quantity').val();
        var sku = $('#sku').val();
        var variantSku = $("#variants option:selected").val();
        var imgUrl = $("#ImgUrl").val();

        var postData = {
            Quantity: quantity,
            Sku: sku,
            VariantSku: variantSku,
            IsGiftCard: 'false'
        };
        $.ajax({
            type: 'POST',
            url: "/umbraco/Api/BasketApi/addToBasket",
            data: JSON.stringify(postData),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            success: function (data) {
                populateBasket(data, imgUrl);
            },
            error: function (f1, f2, f3) {
                $("#variants option[value='" + variantSku + "']").remove();
                $(".description").prepend("<h3 class='alert-danger' style='padding:10px;'>Der er desværre sket en fejl og varen er ikke længere på lager</h3>");
            }
        });
    });
}

//Shows side basket
function ShowBasket() {
    showBasketContent();
    $("#basket").addClass("open");
    $("body").addClass("cart-open");

    //showBasketContent();
    if ($("#menu").hasClass("open")) {
        $('.navbar-toggle').click()
    }
    basketMeasure();
}

//Checks basket for content, if none it shows message
function showBasketContent() {

    var ItemsCount = $('.orderLineRow').length;
    if (ItemsCount <= 0) {
        $('#baske-content').hide();
        $('#basketempty').show();
    } else {
        $('#basketempty').hide();
        $('#baske-content').show();
    }
}

//Handles clik on basket icon for showing basket
function ClickToShowBasket() {
    $("#basket-open").click(function () {
        ShowBasket();
    });

    $("#basket").on("click", "#basket-close", function () {
        $("#basket").removeClass("open");
        $("body").removeClass("cart-open");
        if ($("#menu").hasClass("open")) {
            $('.navbar-toggle').click()
        }
        basketMeasure();
    });

    $(document).on('click', function (event) {
        if (!$(event.target).closest('#basket').length && $("#basket").hasClass("open") && $("#basket").css("right") === "0px") {
            $(this).toggleClass("open");
            $("#basket").toggleClass("open");
            $("body").toggleClass("cart-open");
            basketMeasure();
        }
    });
}

//Measure screen size to fit basket
function basketMeasure() {
    if ($("#basket .items").hasClass("sticky") && ($("#basket .items")[0].offsetHeight + 14) > $("#basket .items")[0].scrollHeight) {
        $("#basket .items").removeClass("sticky");
        $("#basket .footer").removeClass("sticky");
    } else {
        if ($("#basket-items-measure").height() <= $("#basket .items").height()) {
            $("#basket .items").addClass("sticky");
            $("#basket .footer").addClass("sticky");
        }
    }
}

//Sets the SKU for use in basket
function SetSkuToBasket() {
    var selectedValue = $("#variants").val();
    $("#variantsku").val(selectedValue);
}

//Update the basket counter 
function updateBasketIconCounter(counter) {
    $('#basketCounter').text(counter);
}

//Toggle the slide down form of giftcard
function ShowGiftCardForm() {
    //Open voucher form
    $("#giftCard").on("click", ".openvoucher", function (e) {
        e.preventDefault();
        $(".openvoucher").slideUp();
        $(".voucherform").slideDown();
    });

    //Close voucher form
    $("#giftCard").on("click", "#closeVoucher", function (e) {
        e.preventDefault();
        $(".openvoucher").slideDown();
        $(".voucherform").slideUp();
    });
}

//Shows alternativ shipping address
function ShowAltertivAdress() {
    $("#checkout-wrapper").on("click", "#altaddress", function () {
        if ($("#deliveryaddress").is(":visible")) {
            $("#deliveryaddress").slideUp();
        } else {
            $("#deliveryaddress").slideDown();
        }
    });
}

//Changes the amount of a product in basket
function ProductAmountHandler() {
    // Change amount in cart
    $("#basket, #checkout-wrapper").on("click", ".minus, .plus, .line-remove", function (e) {
        e.preventDefault();
        var inc = 'IncreaseQtyOrderlineId';
        var dec = 'DecreaseQtyOrderlineId';
        var qty = 0;
        var $this = $(this);//gets the button pressed
        var OrderlineIdClicked = $this.val();
        var orderLineQty = parseInt($this.attr('qty'));
        var update = $this.attr('upd');
        var clicked = $this.attr('name');
        if (clicked === inc) {
            qty = orderLineQty + 1;
        } else if (clicked === dec) {
            qty = orderLineQty - 1;
        } else {
            qty = orderLineQty;
        }

        var postData = {
            orderLineId: OrderlineIdClicked,
            newQuantity: qty
        };

        $.ajax({
            type: 'POST',
            url: "/umbraco/Api/BasketApi/UpdateOrderLineQty",
            data: JSON.stringify(postData),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            success: function (data) {
                var target = $('[data-orderlineid=' + OrderlineIdClicked + ']');
                //console.log("success");
                //console.log("data", data);
                if (data.LineQty <= 0) {
                    target.remove();
                } else {
                    target.find(".qty").addClass('animated flash').text(data.LineQty);
                    target.find(".line-total").addClass('animated flash').text(data.LineTotal);
                    target.find(".minus, .plus").attr('qty', data.LineQty);
                }

                $(".sidebasket-subtotal").addClass('animated flash').text(data.SubTotal);
                $(".order-shipping").addClass('animated flash').text(data.ShippingTotal);
                $(".order-discounts").addClass('animated flash').text(data.DiscountTotal);
                $(".order-total").addClass('animated flash').text(data.OrderTotal);
                $(".order-moms").addClass('animated flash').text(data.OrderTax);
                $(".shipment-name").text('(' + data.ShippingName + ')');

                showBasketContent();
                updateBasketIconCounter(data.numberOfItems);

                setTimeout(function () {
                    $(".order-shipping, .order-discounts, .order-total, .order-moms, .shipment-name").removeClass('animated flash');
                    target.removeClass('animated flash');
                }, 1000);
                reloadBasket(update);
            },
            error: function (f1, f2, f3) {
                //console.log("error");
            }
        });

    });
}

//Handles changes in shipping methodes
function HandleShippingMethods() {

    $(".shipping-methods .radio.shipping-method input").on("change", function (e) {

        var shippingElem = $(this).closest(".radio.shipping-method");

        shippingElem.addClass("selected").siblings().removeClass("selected");

        var $form = $(this).closest("form");

        $.ajax({
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            data: $form.serialize(),
            method: "POST",
            url: "/umbraco/Surface/ShippingForm/HandleSubmit",
            success: function (data) {
                //console.log("success");
                //console.log("data", data);
                $(".order-shipping").addClass('animated flash').text(data.shippingTotal);
                $(".order-discounts").addClass('animated flash').text(data.discountTotal);
                $(".order-total").addClass('animated flash').text(data.orderTotal);
                $(".order-moms").addClass('animated flash').text(data.taxTotal);
                $(".shipment-name").text('(' + data.shippingName + ')');

                setTimeout(function () {
                    $(".order-shipping, .order-discounts, .order-total").removeClass('animated flash');
                }, 1000);
            },
            error: function (f1, f2, f3) {
                //console.log("error");
            }
        });

    });

}

//Handles showing the giftcard form
function BindVoucher() {
    $("#giftCard form").on("submit", function (e) {
        e.preventDefault();
        var $form = $(e.target);

        $.ajax({
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            data: $form.serialize(),
            method: "POST",
            url: "/umbraco/Surface/Voucher/AddVoucher",
            success: function (data) {
                if (data.success) {

                    PopulateGiftCard(data);

                    $("#giftCardReply").addClass('animated flash');
                    $("#giftCardReply").show();
                    setTimeout(function () {
                        $("#giftCardReply").removeClass('animated flash');
                    }, 500);



                } else {
                    $(".order-subtotal").addClass('animated flash').text(data.subTotal);
                    $(".order-shipping").addClass('animated flash').text(data.shippingTotal);
                    $(".order-discounts").addClass('animated flash').text(data.discountTotal);
                    $(".order-total").addClass('animated flash').text(data.orderTotal);
                    $(".order-tax").addClass('animated flash').text(data.taxTotal);

                    $form[0].reset();
                    setTimeout(function () {
                        $(".order-subtotal, .order-shipping, .order-discounts, .order-total, .order-tax").removeClass('animated flash');
                    }, 1000);
                }

                $(".voucherform").slideUp();
                $(".openvoucher").show();
            },
            error: function (f1, f2, f3) {
                //console.log("error", f1, f2, f3);
            }
        });

    });

    $("#giftCardReply").on("click", "#removeGiftCard", function (e) {
        e.preventDefault();
        var $this = $(this);
        var giftCard = $this.data("giftcardcode");
        var postData = {
            "giftCardCode": giftCard
        };
        //console.log(postData);
        $.ajax({
            type: 'POST',
            url: "/umbraco/Surface/Voucher/removeGiftCardPayment",
            data: JSON.stringify(postData),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            success: function (data) {
                console.log("data ", data);
                if (data.success) {
                    $("#giftCardReply").addClass('animated flash');
                    setTimeout(function () {
                        $("#giftCardReply").removeClass('animated flash');
                        $("#giftCardReply").hide();
                    }, 500);

                }
            },
            error: function (f1, f2, f3) {
                //console.log("error");
            }
        });
    })

    function PopulateGiftCard(data) {
        var reply = data.message;

        reply = "<button id='removeGiftCard' class='btn btn-link' data-giftcardcode='" + data.voucher + "'> <i class='fas fa-times'></i></button>" +
            "<span class='gift-message'>" + data.message + " <br>" +
            "Betales med gavekort " + data.paidWithGiftcard + " - eventuel rest betales resten ved betalingsvinduet</span>"
            //" - rest til betaling " + data.orderTotal + 
            ;
        $("#giftCardReply").addClass('alert alert-info');
        $("#giftCardReply").html(reply);


    }



}

function reloadBasket(update) {
    if (update) {
        location.reload();
    };
}
