$(function () {

    $('#filter-sort .dropdown-menu a').on('click', function (event) {
        event.preventDefault();

        var $target = $(event.currentTarget),
            $this = $(this),
            label = $this.text();
        sortBy = $target.attr('data-value'),
        sortDirection = $target.attr('data-direction');

        var selectedOption = $this.parent("li").addClass("active").siblings().removeClass('active');

        $("#btn-filter-sort > span").text(label);

        console.log("sortBy", sortBy);
        console.log("sortDirection", sortDirection);

        loadProducts(false, sortBy, sortDirection);

        //return false;
    });

    $('.product-list-type').on('click', function (e) {
        e.preventDefault();
        var $this = $(this),
            container = $(this).closest(".category-product-list"),
            productList = container.find(".product-list");

        var elem = $(this).addClass("active").siblings().removeClass('active');

        if ($(this).hasClass("grid")) {
            productList.removeClass("list").addClass("grid");
        }
        else {
            productList.removeClass("grid").addClass("list");
        }
    });

    $('#loadMore').on('click', function (e) {
        e.preventDefault();

        loadProducts(true);
    });

});

function getSorting(sortBy, sortDirection) {

    // Retrieve the object from storage
    var retrievedObject = localStorage.getItem('sorting');
    var sorting = retrievedObject ? JSON.parse(retrievedObject) : null;

    sortBy = typeof sortBy !== 'undefined' ? sortBy : (sorting ? sorting.property : null);
    sortDirection = typeof sortDirection !== 'undefined' ? sortDirection : (sorting ? sorting.direction : null);

    return {
        property: sortBy,
        direction: sortDirection
    }
}

function setSorting(sortObj) {

    if (sortObj.property && sortObj.direction) {

        // Put the object into storage
        localStorage.setItem('sorting', JSON.stringify(sortObj));
    }
}

function loadProducts(loadmore, sortBy, sortDirection) {

    loadmore = typeof loadmore !== 'undefined' ? loadmore : false;
    console.log("loadmore", loadmore);

    var sortObj = getSorting(sortBy, sortDirection);

    setSorting(sortObj);

    var hdnPage = $('input[name=page]'),
        hdnPageSize = $('input[name=pageSize]'),
        hdnPageItems = $('input[name=pageItems]'),
        hdnTotalItems = $('input[name=totalItems]'),
        hdnTotalPages = $('input[name=totalPages]');

    var pageNumber = hdnPage.val(),
        pageSize = hdnPageSize.val(),
        pageItems = hdnPageItems.val(),
        totalItems = hdnTotalItems.val();
    totalPages = hdnTotalPages.val();

    //var rest = pageSize % 5;
    //console.log("pageSize", pageSize);
    //pageSize = pageNumber == 1 && rest > 0 ? rest + 10 : 10;
    //console.log("pageSize", nextPageSize);

    // update page number to request
    pageNumber = loadmore ? (pageNumber < totalPages ? parseInt(pageNumber) + 1 : totalPages) : 1;
    pageItems = parseInt(pageItems);

    var categoryIds = $('input[name=categories]').val();
    console.log("categoryIds", categoryIds);

    var url = "/umbraco/surface/ProductList/GetCategoryProducts" + "?categoryIds=" + categoryIds.toString() + "&page=" + pageNumber + "&pageSize=" + pageSize;
    if (sortObj.property) {
        url += "&sortBy=" + sortObj.property;
    }
    if (sortObj.direction) {
        url += "&sortDirection=" + sortObj.direction;
    }

    console.log("url", url);

    // Update page number and pagesize
    hdnPage.val(pageNumber);
    //hdnPageSize.val(pageSize);

    console.log("---");
    console.log(pageNumber);
    console.log(totalPages);
    console.log("---");
    if (pageNumber >= totalPages) {
        $("#loadMore").hide();
    }
    else {
        $("#loadMore").show();
    }

    $.ajax({
        //contentType: 'application/json; charset=utf-8',
        //dataType: 'json',
        //data: {
        //    "categoryIds": categoryIds
        //},
        url: url,
        cache: false,
        type: "GET",
        traditional: true,
        success: function (data) {
            //console.log(data);
            console.log("PageNumber: " + pageNumber, "PageSize: " + pageSize, "Total items: " + totalItems, "PageTotal: " + totalPages);

            var wrapper = $(".product-list-wrapper");
            var pager = wrapper.find(".pager");

            var products = $(data).find(".product-container");
            if (loadmore) {
                $(products).appendTo(".product-list");

                pageItems = pageItems + products.length;
                hdnPageItems.val(pageItems);

                //pager.html("Side <span>" + pageNumber + "</span> af <span>" + totalPages + "</span>");
                pager.html("Viser <span>" + pageItems + "</span> af <span>" + totalItems + "</span>");
            }
            else {
                $(".product-list").empty();
                $(products).appendTo(".product-list");

                pageItems = products.length;
                hdnPageItems.val(pageItems);

                pager.html("Viser <span>" + pageItems + "</span> af <span>" + totalItems + "</span>");
            }
        },
        error: function (err) {
            console.log(err);
        }
    });
}