$(function () {
    $("#addressform").validate({
        errorClass: "alert alert-danger",
        errorElement: "div",
        rules: {
            FirstName: {
                required: true,
                minlength: 2
            },
            LastName: {
                required: true,
                minlength: 2
            },
            Address: {
                required: true,
                minlength: 2
            },
            PostalCode: {
                required: true,
                minlength: 4,
                maxlength: 4,
                digits: true
            },
            City: {
                required: true,
                minlength: 2
            },
            PhoneNumber: {
                required: true,
                minlength: 8,
                maxlength: 8,
                digits: true
            },
            EmailAddress: {
                required: true,
                email: true
            },

            CompanyName: {
                required: '#ShippingAddress_CompanyName:filled',
                minlength: 2
            },
            AttentionTo: {
                required: '#ShippingAddress_Attention:filled',
                minlength: 2
            },
            AltAdress: {
                required: '#ShippingAddress_Line1:filled',
                minlength: 2
            },
            AltPostalCode: {
                required: '#ShippingAddress_PostalCode:filled',
                minlength: 4,
                maxlength: 4,
                digits: true
            },
            AltCity: {
                required: '#ShippingAddress_City:filled',
                minlength: 2
            },
            terms: "required"
        },
        messages: {
            FirstName: {
                required: "Du har glemt at udfylde dit fornavn",
                minlength: "Dit navn skal være på mindst 2 bogstaver"
            },
            LastName: {
                required: "Du har glemt at udfylde dit efternavn",
                minlength: "Dit navn skal være på mindst 2 bogstaver"
            },
            Address: {
                required: "Du har glemt at udfylde din adresse",
                minlength: "Din adresse skal være på mindst 2 bogstaver"
            },
            PostalCode: {
                required: "Du har glemt at udfylde dit postnummer",
                minlength: "Dit postnummer er for kort",
                maxlength: "Dit postnummer er for langt",
                digits: "Dit postnummer kan kun bestå af tal"
            },
            City: {
                required: "Du har glemt at udfylde din by",
                minlength: "Din by skal være på mindst 2 bogstaver"
            },
            PhoneNumber: {
                required: 'Du har glemt at udfylde dit telefonnummer',
                minlength: "Dit telefonnummer er for kort",
                maxlength: "Dit telefonnummer er for langt",
                digits: "Dit telefonnummer kan kun bestå af tal"
            },
            EmailAddress: {
                required: "Du har glemt at udfylde din email adresse",
                email: "Din email adresse er ikke udfyldt korrekt"
            },

            CompanyName: {
                required: "Du har glemt at udfylde firmanavn til levering",
                minlength: "Dit navn skal være på mindst 2 bogstaver"
            },
            AttentionTo: {
                required: "Du har glemt at udfylde navn til levering",
                minlength: "Dit navn skal være på mindst 2 bogstaver"
            },
            AltAdress: {
                required: "Du har glemt at udfylde adresse til levering",
                minlength: "Dit navn skal være på mindst 2 bogstaver"
            },
            AltPostalCode: {
                required: "Du har glemt at udfylde postnummer til levering",
                minlength: "Dit postnummer er for kort",
                maxlength: "Dit postnummer er for langt",
                digits: "Dit postnummer kan kun bestå af tal"
            },
            AltCity: {
                required: "Du har glemt at udfylde by til levering",
                minlength: "Dit navn skal være på mindst 2 bogstaver"
            },
            shop: "Du skal vælge hvor du vil hente din pakke",
            terms: "Du har glemt at acceptere handelsbetingelserne"
        },
        errorPlacement: function (label, element) {
            if (element.attr("type") == "checkbox") {
                label.insertBefore(element);
            } else if (element.attr("type") == "radio") {
                label.insertAfter("#shippingmodule");
            } else {
                label.insertAfter(element);
            }
        }
    });
});
