$(function () {

    initMouseOverProduct();

});

function initMouseOverProduct() {

    $(".product-container").mouseenter(function (e) {
        //.slideUp();.slideDown();
        $(this).find('.product-overlay-wrapper ').css("display", "flex");
        $(this).find('.btn-group').css({
            'z-index': '4',
            'border': 'solid 1px #FFF',
            'border-radius': '3px'
        });
        //$(this).children('.product-overlay-wrapper ').fadeIn();
    });

    $(".product-container").mouseleave(function (e) {
        $(this).find('.product-overlay-wrapper ').css("display", "none");
        $(this).find('.btn-group').css({
            'z-index': '0',
            'border': 'none',
            'border-radius': '3px'
        });
        //$(this).children('.product-overlay-wrapper ').fadeOut();
    });

}
