$(function () {

    $(window).on("load resize", function (e) {
        var viewport = getViewport();
        recalcNav(viewport, 768);
    });

    var container = $(".nav"),
        navToggle = $("#nav-toggle"),
        navHeader = $(".navbar .navbar-header"),
        subNavTrigger = $(".nav .subnav-trigger");

    navToggle.on("click", function () {
        $("body").toggleClass("nav-open");
        $("#navbar").toggleClass("open");
        $(this).toggleClass("open");
    });

    subNavTrigger.on("click", function () {
        // Toggle dropdown
        var parentElem = $(this).parent("li.dropdown-submenu").toggleClass("open");

        // Close dropdown children/descendants
        parentElem.find("li.dropdown-submenu.open").not(this).removeClass("open");

        // Close dropdown on siblings
        parentElem.siblings("li.dropdown-submenu").removeClass("open").find("li.dropdown-submenu.open").removeClass("open");

        var x = $(this).attr("aria-expanded");
        if (x === "true") {
            $(this).attr("aria-expanded", "false");
        }
        else {
            $(this).attr("aria-expanded", "true");
        }
    });

    $(document).on("click", function (e) {
        if (!container.is(e.target) && container.has(e.target).length === 0 &&
            !navToggle.is(e.target) && !navHeader.is(e.target)) {
            //console.log("click");
            closeNav();
        }
    });
});

function closeNav() {
    $("ul.nav li").each(function () {
        $(this).removeClass("open");
    });
}

function recalcNav(viewport, breakpoint) {

    // Expand current subnav on mobile
    $("ul.nav li.dropdown-submenu").each(function () {
        if ($(this).hasClass("selected")) {
            viewport.width < breakpoint ? $(this).addClass("open") : $(this).removeClass("open");
        }
    });
}

function getViewport() {
    var e = window, a = 'inner';
    if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
    }
    return { width: e[a + 'Width'], height: e[a + 'Height'] };
}