$(function () {
    //disablePayment(true);
    SelectPickupPoint();
    acceptTermsFunction();

    var shippingMethod = $("#Shipping-frame").data("shipping");
    var acceptTerms = false;

    submitForPayment();//shows allert if terms not accepted
    switch (shippingMethod) {
        case "gls":
            //disablePayment(true);
            hideAlternativAddress();
            showPickupPoints($("#Shipping-frame").data("shipping"));
            break;
        case "GLSDK_HD":
            hideAlternativAddress();
            setCarierOnBasket();
            break;
        case "GLSDK_BP":
            $("#altaddress").prop("checked", true);
            $("#deliveryaddress").slideDown();
            $("input").prop('required', true);
            acceptTerms = true;
            setCarierOnBasket();
            break;
        case "email":
            acceptTerms = true;
            hideAlternativAddress();
            //setCarierOnBasket();
            break;
        default:
    }

    function submitForPayment() {
        $(".paymentBtn").click(function (e) {
            e.preventDefault();
            console.log(IsTermsAccepted());
            console.log(acceptTerms);
            if (IsTermsAccepted() && acceptTerms) {
                $('#addressform').submit();
            } else {
                $('#terms-error').show();
            }

        });

    }


    function setCarierOnBasket() {
        var PickupId = null;
        var BasketId = $("#Shipping-frame").data("basketid");
        var PickupAddress = null;
        var CarrierCode = $("#Shipping-frame").data("shipping");
        
        $("#BillingAddress_PostalCode").focusout(function () {
            var postData = {
                BasketId: BasketId,
                PickupID: PickupId,
                CarrierCode: CarrierCode,
                PickupAddress: PickupAddress
            };

            $.ajax({
                type: "POST",
                url: "/umbraco/api/BasketApi/SetShippingMethode",
                data: JSON.stringify(postData),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (data) {
                    acceptTerms = true;
                },
                error: function (f1, f2, f3) {
                    //console.log("error");
                }
            });
        });



    };

    function showPickupPoints(_carrier) {

        var _countryCode = "DK"// $("#Shipping-frame").data("country");
        var _zipCode = '';
        var _city = '';

        $('#BillingAddress_PostalCode').focusout(function () {
            _zipCode = $('#BillingAddress_PostalCode').val();
            GetPicupPoints(_countryCode, _carrier, _zipCode, _city)
        });
        $('#BillingAddress_City').focusout(function () {
            _city = $('#BillingAddress_City').val();
            GetPicupPoints(_countryCode, _carrier, _zipCode, _city);
        });

    }

    function GetPicupPoints(_countryCode, _carrier, _zipCode, _city) {
        if (_zipCode !== '' && _city !== '') {

            var postData = {
                CountryCode: _countryCode,
                CarrierCode: _carrier,
                ZipCode: _zipCode,
                City: _city
            };

            $.ajax({
                type: 'POST',
                url: "/umbraco/api/PakkeLabel/GetPickupPointsByCarrier",
                data: JSON.stringify(postData),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                success: function (data) {
                    //console.log("success");
                    //console.log("data", data);

                    data = $.parseJSON(data);

                    var items = [];
                    if (data.length >= 1) {
                        $.each(data, function (i, item) {
                            //console.log(item);
                            var PickupAddress = item.company_name + ' - ' + item.address + ' - ' + item.zipcode + ' - ' + item.city;
                            items.push('<li class="selectPickupPoint" data-id="' +
                                item.id + '" data-pickupaddress="' +
                                PickupAddress + '"><strong>' +
                                item.company_name + '</strong> ' +
                                item.address + ' ' +
                                item.zipcode + ' ' +
                                item.city + '</li>');
                        });
                    }
                    else {
                        items.push('<li class="" >Der er sket en fejl, der kunne ikke findes en pakkeshop på dit postnummer</li>');
                    }

                    $('#pickUpPoints').html(items.join(''))
                    $("#Shipping-frame").slideDown();
                },
                error: function (f1, f2, f3) {
                    //console.log("error");
                }
            });

        }

    };

    function SelectPickupPoint() {
        $("#Shipping-frame").on("click", ".selectPickupPoint", function (e) {
            e.preventDefault();

            var $this = $(this);
            var PickupId = $this.data("id");
            var BasketId = $("#Shipping-frame").data("basketid");
            var PickupAddress = $this.data("pickupaddress");
            var CarrierCode = 'GLSDK_SD';
            var postData = {
                BasketId: BasketId,
                PickupID: PickupId,
                CarrierCode: CarrierCode,
                PickupAddress: PickupAddress
            };

            $.ajax({
                type: "POST",
                url: "/umbraco/api/BasketApi/SetShippingMethode",
                data: JSON.stringify(postData),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (data) {
                    //console.log("success");
                    //console.log("data", data);
                    var selected = $("ul").find("[data-id='" + PickupId + "']");
                    $("li").removeClass("selected");
                    selected.addClass('selected');
                    acceptTerms = true;
                    //disablePayment(false);

                },
                error: function (f1, f2, f3) {
                    //console.log("error");
                }
            });
        });
    };

    function hideAlternativAddress() {
        $('#alternativDelivery').css("display", "none");
    }

    function acceptTermsFunction() {
        $("#acceptTerms").on("click", ".terms", function (e) {
            //disablePayment(false);
        });

    }

    function IsTermsAccepted() {
        return $("#terms").prop('checked');
    }

    function disablePayment(enable) {

        if (IsTermsAccepted() && acceptTerms) {
            $(".paymentBtn").prop("disabled", enable);
        }
        else {
            $(".paymentBtn").prop("disabled", true);
        }
    };
});
