$(function () {
    console.log('owl');
    if ($(".owl-carousel-related").length) {
        var owlConfig = {
            stagePadding: 0,
            items: 1,
            loop: false,
            margin: 24,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            center: false,
            nav: true,
            dots: false,
            navText: [
                "<i class='fas fa-chevron-left'></i>",
                "<i class='fas fa-chevron-right'></i>"
            ],
            lazyLoad: true,
            responsiveClass: true,
            responsiveRefreshRate: true,
            responsive: {
                0: {
                    items: 2
                },
                480: {
                    items: 2
                },
                768: {
                    items: 3
                },
                960: {
                    items: 4
                },
                1200: {
                    items: 4
                },
                1500: {
                    items: 6
                }
            }
        };

        var owl = $('.owl-carousel-related').owlCarousel(owlConfig);

        $('.owl-carousel-related .owl-nav > div').on('click', function (e) {
            owl.trigger('stop.owl.autoplay');
        })

    }
});