$(function () {
    $("#mini-cart").on("click", "a.basket-small", function (e) {
        e.preventDefault();
        //e.stopPropagation();
        $(".shopping-cart").fadeToggle("fast");
    });

    $("#mini-cart").on("click", ".btn-close", function (e) {
        e.preventDefault();
        $(".shopping-cart").fadeOut("fast");
    });
});